@font-face {
  font-family: "Grotesque-Bold";
  src: url("./fonts/RG-StandardBold.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Grotesque-SemiBold";
  src: url("./fonts/RG-StandardSemibold.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "Grotesque-Medium";
  src: url("./fonts/RG-StandardMedium.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: "Grotesque-Light";
  src: url("./fonts/RG-SlimBook.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Grotesque-Light";
  src: url("./fonts/RG-StandardBook.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}

body {
  margin: 0;
  background: #f8f7f8;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* common */
.title h1 {
  font-size: 48px;
  font-family: "Grotesque-Medium", sans-serif;
  width: 770px;
}
.title span {
  font-size: 48px;
  background: linear-gradient(to right, #019eab, #9f2e88);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contactAll .contactCard {
  width: 270px;
  height: 250px;
  padding: 20px;
  border-radius: 20px;
  background: #ffff;
  transition: 200ms;
  cursor: pointer;
}
.contactAll .contactCard:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.contactAll .contactCard:hover h1 {
  background: linear-gradient(to right, #019eab, #500441);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contactAll .contactCard h1 {
  font-size: 24px;
  font-family: "Grotesque-Medium", sans-serif;
  color: #1b1b1b;
  transition: 200ms;
}
.contactAll .contactCard .double {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 130px;
}
.contactAll .contactCard .double a {
  color: #9b9fb1;
  font-size: 18px;
  font-family: "Grotesque-Light", sans-serif;
  text-decoration: none;
}
@media (max-width: 765px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .title h1 {
    font-size: 24px;
    margin-bottom: 20px;
    width: 100%;
  }
  .title span {
    font-size: 24px;
  }
}
/* common */

/* loader */
.portLocal .wrappedLoader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.newtons-cradle {
  --uib-size: 50px;
  --uib-speed: 1.2s;
  --uib-color: #474554;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--uib-size);
  height: var(--uib-size);
}
.newtons-cradle__dot {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 25%;
  transform-origin: center top;
}

.newtons-cradle__dot::after {
  content: "";
  display: block;
  width: 100%;
  height: 25%;
  border-radius: 50%;
  background-color: var(--uib-color);
}

.newtons-cradle__dot:first-child {
  animation: swing var(--uib-speed) linear infinite;
}

.newtons-cradle__dot:last-child {
  animation: swing2 var(--uib-speed) linear infinite;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }

  25% {
    transform: rotate(70deg);
    animation-timing-function: ease-in;
  }

  50% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes swing2 {
  0% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }

  50% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }

  75% {
    transform: rotate(-70deg);
    animation-timing-function: ease-in;
  }
}

.loader {
  width: 44.8px;
  height: 44.8px;
  color: #554cb5;
  position: relative;
  background: radial-gradient(11.2px, currentColor 94%, #0000);
}

.loader:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background: radial-gradient(10.08px at bottom right, #0000 94%, currentColor)
      top left,
    radial-gradient(10.08px at bottom left, #0000 94%, currentColor) top right,
    radial-gradient(10.08px at top right, #0000 94%, currentColor) bottom left,
    radial-gradient(10.08px at top left, #0000 94%, currentColor) bottom right;
  background-size: 22.4px 22.4px;
  background-repeat: no-repeat;
  animation: loader 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
}

@keyframes loader {
  33% {
    inset: -11.2px;
    transform: rotate(0deg);
  }

  66% {
    inset: -11.2px;
    transform: rotate(90deg);
  }

  100% {
    inset: 0;
    transform: rotate(90deg);
  }
}
.mainLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 350px;
}
/* loader */

/* header starts */
.header {
  padding: 40px 0;
  position: fixed;
  width: 100%;
  background-color: #f8f7f8;
  z-index: 99;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
}

.navbar .navlink {
  width: 100%;
}

.navbar .navlink ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar .navlink ul li {
  list-style-type: none;
}

.navbar .navlink ul li a {
  text-decoration: none;
  font-size: 18px;
  font-family: "Grotesque-Light", sans-serif;
  color: #1b1b1b;
  cursor: pointer;
}

.navlink ul li a.active {
  color: #9b9fb1;
}

.navbar button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 100px;
  gap: 10px;
  width: 300px;
  background: #1b1b1b;
  color: #fff;
  font-size: 18px;
  font-family: "Grotesque-Medium", sans-serif;
  cursor: pointer;
}

.hide {
  display: none;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger.active + .hamburgerLi {
  display: block;
}

.hamburgerLi {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 999;
}
.opened ul li a.active {
  color: #9b9fb1;
  background: #f8f7f8;
}
.opened ul button {
  display: none;
}
.opened {
  position: fixed;
  padding: 50px;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #f8f7f8;
}

.opened .contactAll {
  display: flex;
  flex-flow: row wrap;
  margin-top: 100px;
  gap: 50px;
}

.opened .close {
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}
.opened ul {
  list-style-type: none;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.opened ul a {
  text-decoration: none;
  font-size: 18px;
  font-family: "Grotesque-Light", sans-serif;
  color: #1b1b1b;
  padding: 13px 20px;
  background: #f8f7f8;
}
.opened ul a:hover {
  background: #f0e4ee;
  border-radius: 50px;
  padding: 13px 20px;
}
.opened .logoHamburger {
  display: block;
}
.opened .language {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .navbar .navlink {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 30px;
  }

  .navbar .navlink ul li {
    display: none;
  }

  .header .hamburger {
    display: block;
  }
}

@media (max-width: 765px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .header {
    padding: 10px 0;
  }
  .opened ul {
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    gap: 30px;
  }
  .opened ul button {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 5px 10px;
    font-size: 15px;
    margin-top: 50px;
  }
  .opened .contactAll {
    display: none;
  }
  .opened ul {
    margin-top: 0;
  }
  .opened .logoHamburger {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .opened ul {
    gap: 55px;
  }
  .navbar button {
    display: none;
  }
  .navbar {
    gap: 50px;
  }
}
/* header ends */

/* headerBelow start */
.below {
  padding: 200px 0 50px 0;
  position: relative;
}
.below .belowWrapper {
  display: flex;
  align-items: end;
  justify-content: end;
  position: relative;
}

.below .animation img {
  width: 100%;
  height: 100%;
}
.below .writeSection {
  position: absolute;
  left: 0;
  bottom: 150px;
}
.below .writeSection p {
  width: 470px;
  color: #5b5b5b;
  font-size: 24px;
  font-family: "Grotesque-Light", sans-serif;
}

.below .writeSection h1 {
  font-size: 77px;
  font-family: "Grotesque-Light", sans-serif;
  width: 600px;
  margin-top: 30px;
}

.below .writeSection span {
  font-size: 77px;
  background: linear-gradient(to right, #019eab, #9f2e88);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.below button {
  cursor: pointer;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  gap: 10px;
  padding: 10px;
  border-radius: 100px;
  width: 300px;
  font-size: 18px;
  font-family: "Grotesque-Light", sans-serif;
}
@media (max-width: 1200px) and (min-width: 992px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 992px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 765px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .below .writeSection p {
    width: 100%;
  }
  .below .writeSection h1 {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .below .writeSection {
    position: static;
    margin-top: 350px;
  }
  .below .writeSection p {
    width: 100%;
    font-size: 15px;
  }
  .below .writeSection h1 {
    width: 100%;
    font-size: 39px;
    font-family: "Grotesque-Light", sans-serif;
    margin-top: 20px;
    margin-bottom: 60px;
  }
  .below .writeSection span {
    width: 100%;
    font-size: 39px;
  }
  .below button {
    width: 100%;
  }
  .animation {
    position: absolute;
    top: -50px;
    left: 100px;
  }
  .below .animation img {
    width: auto;
    height: auto;
  }
  .below {
    overflow: hidden;
  }
}
/* headerBelow ends */

/* AeroBusines starts */
.airBusines {
  padding: 100px 0;
}
.airBusines .firstBusines {
  display: flex;
  align-items: center;
  margin-bottom: 150px;
}
.airBusines .forWord h1 {
  font-size: 48px;
  font-family: "Grotesque-Light", sans-serif;
  width: 580px;
}

.airBusines .forWord p {
  font-size: 24px;
  font-family: "Grotesque-Light", sans-serif;
  width: 540px;
  margin-top: 30px;
  color: #5b5b5b;
}

.airBusines .forWord span {
  font-size: 48px;
  background: linear-gradient(to right, #019eab, #9f2e88);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.airBusines .secondBusines .above {
  display: flex;
  align-items: end;
  justify-content: end;
}
.airBusines .secondBusines .above .inner {
  width: 585px;
}
.airBusines .secondBusines .above p {
  font-size: 24px;
  font-family: "Grotesque-Light", sans-serif;
  width: 540px;
  margin-top: 30px;
  color: #0d0938;
}

.airBusines .secondBusines .above span {
  font-size: 48px;
  background: linear-gradient(to right, #019eab, #9f2e88);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.airBusines .secondBusines .above h1 {
  font-size: 48px;
  font-family: "Grotesque-Light", sans-serif;
  width: 580px;
}
.airBusines .secondBusines .below {
  margin-top: 30px;
  position: relative;
}

.airBusines .secondBusines .below .smoke {
  position: absolute;
  top: -235px;
  left: -35px;
}

.airBusines .secondBusines .below .play {
  position: absolute;
  top: 55%;
  left: 160px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: #ffff;
  font-size: 24px;
  font-family: "Grotesque-Light", sans-serif;
  cursor: pointer;
}
.airBusines .forImage img {
  width: 100%;
  height: 100%;
}
@media (max-width: 1200px) and (min-width: 992px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 992px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .airBusines .firstBusines {
    flex-direction: column;
    margin-bottom: 50px;
  }
  .airBusines .forWord h1 {
    width: 80%;
  }
  .airBusines .forWord p {
    width: 80%;
    margin-bottom: 50px;
  }
  .airBusines .secondBusines .above {
    z-index: 9;
    position: relative;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .airBusines .forWord h1 {
    font-size: 24px;
    width: 100%;
  }
  .airBusines .secondBusines .above span {
    font-size: 24px;
  }
  .airBusines .forWord p {
    width: 100%;
    font-size: 18px;
  }
  .airBusines .secondBusines .above p {
    font-size: 18px;
    width: 100%;
  }
  .airBusines .secondBusines .above h1 {
    font-size: 24px;
    width: 100%;
  }
  .airBusines .secondBusines .below .smoke {
    top: -386px;
    left: -150px;
  }
  .below .videoImg {
    width: 100%;
  }
  .airBusines .secondBusines .below .play {
    left: 18%;
    font-size: 19px;
  }
  .airBusines .forWord span {
    font-size: 24px;
  }
  .airBusines {
    padding: 50px 0;
  }
}
/* AeroBusines ends */

/* videokeys start */
.videoKeys {
  padding: 100px 0;
  position: relative;
}
.courses {
  padding-top: 50px;
}
.swiper-wrapper {
  gap: 10px;
}
.video-wrapper video {
  border-radius: 30px;
  width: 377px;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  color: #000;
  font-size: 28px !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: #000;
  font-size: 28px !important;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  background: linear-gradient(to right, #83e1e9, #ea96d9);
  padding: 0 5px 0 0;
  border-radius: 50%;
  width: 70px !important;
  height: 70px !important;
  top: 15% !important;
  left: 86% !important;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  background: linear-gradient(to right, #83e1e9, #ea96d9);
  padding: 0 0 0 3px;
  border-radius: 50%;
  top: 15% !important;
  width: 70px !important;
  height: 70px !important;
}
.swiper-backface-hidden .swiper-slide {
  position: relative;
}
.swiper {
  position: static !important;
}
.videoKeys .videoAll {
  position: relative;
}
.videoKeys .play {
  top: 46%;
  position: absolute;
  z-index: 999;
  left: 145px;
  cursor: pointer;
  display: none;
}
.swiper-backface-hidden .swiper-slide:hover .play {
  display: block;
}
.videoKeys .info {
  margin-bottom: 50px;
}
.videoKeys .info h1 {
  font-size: 48px;
  font-family: "Grotesque-Light", sans-serif;
  width: 680px;
}
.videoKeys .info span {
  font-size: 48px;
  background: linear-gradient(to right, #019eab, #9f2e88);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 1200px) and (min-width: 992px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 20% !important;
    left: 69% !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 20% !important;
  }
}

@media (max-width: 992px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .videoKeys .info {
    margin-bottom: 150px;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 20% !important;
    left: 76% !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 20% !important;
  }
  .video-wrapper video {
    width: 359px;
  }
}

@media (max-width: 765px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .videoKeys .info h1 {
    width: 100%;
    font-size: 35px;
  }
  .videoKeys .info {
    margin-bottom: 150px;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 20% !important;
    left: 69% !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 20% !important;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .videoKeys .info h1 {
    font-size: 24px;
  }
  .videoKeys .play {
    left: 143px;
  }
  .videoKeys .info span {
    font-size: 24px;
  }
  .videoKeys .info {
    margin-bottom: 150px;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 20% !important;
    left: 0% !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 20% !important;
  }
  .videoKeys {
    padding: 50px 0;
  }
  .swiper-wrapper {
    gap: 0;
  }
}
/* videokeys end */

/* catalog start */
.catalog {
  padding: 50px 0;
}

.catalog .catalogTitle p {
  font-size: 24px;
  font-family: "Grotesque-Light", sans-serif;
  width: 200px;
  margin-top: 30px;
  color: #5b5b5b;
}

.catalog .double {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.catalog .double p {
  width: 500px;
  font-family: "Grotesque-Light", sans-serif;
}
.catalog a {
  text-decoration: none;
}
.catalog button {
  cursor: pointer;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  gap: 10px;
  padding: 10px;
  border-radius: 100px;
  width: 300px;
  font-size: 18px;
  font-family: "Grotesque-Light", sans-serif;
}
.catalog .catalogAll {
  margin-top: 60px;
}
.catalog .catalogCard {
  display: flex;
  flex-flow: row wrap;
  gap: 30px;
  margin-bottom: 60px;
}

.portLocal .portButton {
  display: flex;
  align-items: center;
  justify-content: center;
}
.catalog .catalogCard .card {
  width: 380px;
  height: 445px;
  background: #fff;
  border-radius: 30px;
  padding: 20px;
}
.catalog .catalogCard .card2 img {
  width: 100%;
  height: 100%;
}
.catalog .catalogCard .card .number {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.catalog .catalogCard .card .number span {
  font-size: 24px;
  font-family: "Grotesque-Light", sans-serif;
}

.catalogAll .mainTartib {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.catalogAll .tartib {
  font-size: 24px;
  border: 1px solid #9f2e88;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.catalog .catalogCard .card .parametr h1 {
  color: #1b1b1b;
  font-size: 24px;
  font-family: "Grotesque-Light", sans-serif;
  margin-bottom: 20px;
}
.catalog .catalogCard .card .parametr .innerParametr {
  display: flex;
  color: #5b5b5b;
  font-size: 18px;
  font-family: "Grotesque-Light", sans-serif;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
  margin-bottom: 16px;
}
.catalog .catalogCard .card .parametr .innerParametr p:nth-child(1) {
  width: 180px;
}
.catalog .catalogCard .card .parametr .innerParametr:nth-last-child(1) {
  border: none;
}

@media (max-width: 1200px) and (min-width: 992px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 992px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 765px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .catalog .double p {
    width: 100%;
  }
  .catalog .double {
    flex-direction: column;
  }
  .catalog button {
    width: 100%;
    justify-content: space-between;
  }
  .catalog.double p {
    width: 100%;
  }
}

/* catalog end */

/* coworkers start */
.coworkers {
  padding: 100px 0;
}
.coworkers .title h1 {
  width: 570px;
}
.coworkers .allCoworkers {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  overflow: hidden;
}
.coworkers .allCoworkers .disp {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 25px;
}
.coworkers .allCoworkers .disp p {
  border-radius: 100px;
  border: 1px solid #9b9fb1;
  color: #9b9fb1;
  padding: 30px 40px;
}
.showButton {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 765px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .coworkers .title h1 {
    width: 100%;
  }
  .coworkers {
    padding: 10px 0;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .coworkers .title h1 {
    width: 100%;
  }
}
/* coworkers end */

/* hakor start */
.hamkor {
  padding: 50px 0;
}
.hamkor .title {
  display: flex;
  justify-content: end;
  margin-bottom: 40px;
}
.logo-slider {
  overflow: hidden;
  padding: 30px 0 0 0;
  white-space: nowrap;
}

.logos-slide {
  display: inline-block;
  animation: slide 45s infinite linear;
  margin-bottom: 50px;
  margin-left: -50px;
}
.logos-slide2 {
  display: inline-block;
  animation: slide2 45s infinite linear;
  margin-bottom: 50px;
  margin-left: -96px;
}

.logos-slide img,
.logos-slide2 img {
  width: 183px;
  height: 83px;
  margin: 0 10px;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slide2 {
  0% {
    transform: translateX(1%);
  }
  100% {
    transform: translateX(100%);
  }
}

@media (max-width: 1200px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
}

/* hakor end */

/* contact start */
.contact {
  padding: 100px 0;
}
.title h1 {
  font-size: 48px;
  font-family: "Grotesque-Light", sans-serif;
  width: 710px;
}
.title span {
  font-size: 48px;
  background: linear-gradient(to right, #019eab, #9f2e88);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact .contactAll {
  display: flex;
  flex-flow: row wrap;
  gap: 35px;
  margin-top: 50px;
}

@media (max-width: 1200px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 765px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .title h1 {
    font-size: 24px;
    width: 100%;
  }
  .title span {
    font-size: 24px;
  }
  .contact {
    padding: 10px 0;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .contactAll .contactCard {
    width: 100%;
  }
}
/* contact end */

/* submir start */
.submit {
  padding: 50px 0;
  position: relative;
}
.submit .title h1 {
  width: 700px;
}
.submit input[type="text"] {
  text-transform: capitalize;
}
.input {
  width: 750px;
  border-radius: 30px;
  background: #fff;
  padding: 60px;
  margin-top: 50px;
}
.input form {
  position: relative;
  margin-bottom: 50px;
}
.input input {
  width: 100%;
  border: 1px solid #9b9fb1;
  border-radius: 50px;
  padding: 20px;
  outline: none;
  color: #9b9fb1;
  font-family: "Grotesque-Regular", sans-serif;
}
::placeholder {
  color: #9b9fb1;
}
.input form p {
  color: #9b9fb1;
  background: #fff;
  position: absolute;
  left: 17px;
  font-size: 19px;
  font-family: "Grotesque-Light", sans-serif;
  padding: 0 2px;
}
.input form .firstP {
  top: -15px;
}
.input form .secondP {
  top: 92px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.submit .inputCard {
  display: flex;
  align-items: center;
  justify-content: end;
}
.submit .buttons {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 80px;
}
.submit .buttons h5 {
  color: #9b9fb1;
  font-family: "Grotesque-Light", sans-serif;
}
.submit .buttons span {
  color: #000;
}
.submit button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  border-radius: 100px;
  background: #1b1b1b;
  color: #fff;
  font-size: 18px;
  font-family: "Grotesque-Light", sans-serif;
  width: 450px;
}
.submit .animation {
  position: absolute;
  top: 20%;
  left: -7%;
  z-index: -1;
}
@media (max-width: 1200px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .submit .inputCard {
    justify-content: center;
  }
}

@media (max-width: 765px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .submit .title h1 {
    width: 100%;
  }
  .submit {
    overflow: hidden;
  }
  .submit .buttons {
    flex-direction: column;
    padding: 20px 0;
    margin-top: 30px;
  }
  .submit button {
    width: 100%;
  }
  .input form {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .submit .title h1 {
    width: 100%;
  }
  .input {
    width: 100%;
    padding: 40px 20px 0px 20px;
  }
}
/* submir end */

/* footer start */
.footer {
  padding: 200px 0 50px 0;
}
.footer .footerAll {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer .footerAll .right {
  display: flex;
  gap: 50px;
  color: #1b1b1b;
  font-size: 18px;
  font-family: "Grotesque-Light", sans-serif;
}
@media (max-width: 1200px) and (min-width: 992px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 992px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 765px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .footer {
    padding: 100px 0 50px 0;
  }
  .footer .footerAll {
    flex-direction: column;
  }
  .footer .footerAll .right {
    flex-direction: column;
    margin-top: 25px;
    gap: 25px;
    align-items: center;
  }
}
/* footer end */

/* portLocal start */
.portLocal {
  padding: 150px 0 50px 0;
}

.portLocal .title p {
  width: 500px;
  font-size: 24px;
  margin-top: 30px;
  font-family: "Grotesque-Light", sans-serif;
}
.portLocal .buttonHolder {
  display: flex;
  align-items: center;
  justify-content: center;
}
.portLocal button {
  background: #1b1b1b;
  color: #fff;
}
.portLocal .navigated {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 80px;
}
.portLocal .navigated p {
  font-size: 18px;
  color: #9b9fb1;
  font-family: "Grotesque-Light", sans-serif;
  cursor: pointer;
}
.portLocal .navigated h5 {
  font-size: 18px;
  color: #1b1b1b;
  font-family: "Grotesque-Light", sans-serif;
}
@media (max-width: 1200px) and (min-width: 992px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 992px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 765px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .portLocal .title p {
    width: 100%;
  }
}
/* portLocal end */

.scroller {
  max-width: 100%;
  margin-top: -30px;
  margin-bottom: 20px;
}

.scroller img {
  height: 100px;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  gap: 30px;
}

.scroller[data-animated="true"] {
  overflow: hidden;
}

.scroller__inner p {
  color: #9b9fb1;
  font-family: "Grotesque-Light", sans-serif;
  font-weight: 300;
  font-size: 35px;
  border: 1px solid #9b9fb1;
  border-radius: 100px;
  padding: 25px;
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 300s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 400s;
}
/* 500 */

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

.reversed .scroller[data-speed="fast"] {
  --_animation-duration: 300s;
}

.reversed .scroller[data-speed="slow"] {
  --_animation-duration: 400s;
}
/* 427 */

/* general styles */

.tag-list {
  margin: 0;
  padding-inline: 0;
  list-style: none;
}

.tag-list li {
  padding: 1rem;
  background: var(--clr-primary-400);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
}

.test {
  background: red !important;
}

.custom-toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50; /* Green background */
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: fadeInOut 5s forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
